import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/page/app',
		alias: '/',
		component: () => import('./views/app'),
		redirect: '/page/app/home',
		beforeEnter: AuthRequired,
		children: [
			{
				path: 'home',
				component: () => import(
					'./views/app/home'
				),
			},
			{
				name: 'customers',
				path: '/page/app/klanten',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customers')
			},
			{
				name: 'customers-specification',
				path: '/page/app/klanten/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customers/customer-specification')
			},
			{
				name: 'suppliers',
				path: '/page/app/leveranciers',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/suppliers')
			},
			{
				name: 'suppliers-specification',
				path: '/page/app/leveranciers/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/suppliers/supplier-specification')
			},
			{
				name: 'products',
				path: '/page/app/producten',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/products')
			},
			{
				name: 'products-specification',
				path: '/page/app/producten/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/products/product-specification')
			},
			{
				name: 'orders',
				path: '/page/app/zichten',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/orders')
			},
			{
				name: 'invoices',
				path: '/page/app/facturen',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoices')
			},
			{
				name: 'orders',
				path: '/page/app/zichten/:type',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/orders')
			},
			{
				name: 'invoices',
				path: '/page/app/facturen/:type',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoices')
			},
			{
				name: 'actions',
				path: '/page/app/acties',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/actions')
			},
			{
				name: 'gifts',
				path: '/page/app/cadeaubonnen',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/gifts')
			},
			{
				name: 'management',
				path: '/page/app/beheer',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/management')
			},
			{
				name: 'snelstart',
				path: '/page/app/snelstart',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/snelstart')
			},
			{
				name: 'dashboard',
				path: '/page/app/dashboard',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/dashboard')
			},
			{
				name: 'git',
				path: '/page/app/git',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/git')
			},
			{
				name: 'inkoopzichten',
				path: '/page/app/inkoopzichten/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/orders/order-specification.vue')
			},
			{
				name: 'verkoopzichten',
				path: '/page/app/verkoopzichten/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/orders/order-specification.vue')
			},
			{
				name: 'inkoopfactuur',
				path: '/page/app/inkoopfactuur/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoices/invoice-specification.vue')
			},
			{
				name: 'verkoopfactuur',
				path: '/page/app/verkoopfactuur/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoices/invoice-specification.vue')
			},
			{
				name: 'emailTemplates',
				path: '/page/app/template_settings/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/management/email-templates.vue')
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ path: 'login', component: () => import('./views/public/user/Login')  },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'change-password', component: () => import('./views/public/user/ChangePassword') },
		],

	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
