/* eslint-disable no-unused-vars */
import { products } from '@/constants/config'
import { functions } from '@/mixins/functions'

export default {
	state: {
		products: localStorage.getItem('products') != null ? JSON.parse(localStorage.getItem('products')) : null,
	},
	getters: {
		products: state => state.products,
	},
	mutations: {
		setProducts (state, products) {
			state.products = products;
		},
	},
	actions: {
		getProducts ({ commit }) {
			functions.methods.req('GET', '/product/getallwithstock').then((response) => {
				if (response.status) {
					commit('setProducts', response.data);
				} else {
					commit('setProducts', []);
				}
			});
		}
	}
}