import Vuex from 'vuex'

import app from '../main'
import menu from './modules/menu'
import user from './modules/user'
import product from './modules/product'
import country from './modules/country'

export const store = new Vuex.Store({
	state: {
		
	},
	getters: {
		
	},
	mutations: {
		changeLang (state, payload) {
			app.$i18n.locale = payload
			localStorage.setItem('currentLanguage', payload)
		}
	},
	actions: {
		setLang ({ commit }, payload) {
			commit('changeLang', payload)
		}
	},
	modules: {
		menu,
		user,
		country,
		product
	}
})
